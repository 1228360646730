<template>
  <div class="remote-working-view">
    <HubRemoteWorking
      name="remote-working"
      :overview="overview"
      :user="user"
      v-if="loaded"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HubRemoteWorking from '@blocks/hubs/HubRemoteWorking'
import updateBreadcrumb from '@mixins/updateBreadcrumb'

export default {
  name: 'RemoteWorkingView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubRemoteWorking
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      overview: 'remoteWorking/getOverview',
      user: 'remoteWorking/getUser'
    })
  },
  created () {
    this.fetchGetPage({ service: 'remote-working' }).then(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      'fetchGetPage': 'page/fetchGetPage'
    })
  }
}
</script>

<style lang="scss">
  .remote-working-details{
    &__select{
      @include icon(icons("chevron-down"));
      position: relative;
      max-width: 22rem;
      &:before{
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: colors('primary');
        display: block;
        width: 2rem;
        height: 2rem;
      }
      select{
        margin-bottom: 0;
        padding-right: 5rem;
        color: #404040;
        background-color: colors('foreground');
        border-color: colors('border');
      }
    }
  }
</style>
