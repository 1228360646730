<template>
  <ContainerHub class="hub-remote-working">
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description" />
    </template>

    <template slot="body">
      <CardUser v-if="user" :display-type="'remoteWorking'" :content="user" />

      <HubHeader
        class="remote-working-details__title"
        :titleTag="'h3'"
        :title="$t('message.remoteWorking.overview.title')" />

      <ElementTable class="table--remote-working" v-if="overview">
        <template slot="header">
          <RemoteWorkingTableHeader />
        </template>

        <template slot="body">
          <RemoteWorkingTableBody v-for="(item, index) in overview" :content="item" :key="`overview-${index}`" />
        </template>
      </ElementTable>

      <BaseButton
        icon="more"
        icon-position="right"
        color="primary"
        v-if="offset < total"
        @click.native="handleClick">
          {{ $t(`message.common.buttons.viewMore`) }}
      </BaseButton>
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import CardUser from '@cards/user/CardUser'
import ElementTable from '@elements/ElementTable'
import RemoteWorkingTableHeader from '@blocks/remote-working/RemoteWorkingTableHeader'
import RemoteWorkingTableBody from '@blocks/remote-working/RemoteWorkingTableBody'

export default {
  name: 'HubRemoteWorking',
  props: {
    name: {
      type: String,
      required: true
    },
    overview: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selected: ''
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      offset: 'remoteWorking/getOffsetOverview',
      total: 'remoteWorking/getTotalOverview'
    }),
    displayType () {
      return 'large'
    }
  },
  components: {
    BaseButton,
    HubHeader,
    ContainerHub,
    CardUser,
    ElementTable,
    RemoteWorkingTableHeader,
    RemoteWorkingTableBody
  },
  methods: {
    ...mapActions({
      fetchGetAddRemoteWorkingOverview: 'page/fetchGetAddRemoteWorkingOverview'
    }),
    handleClick () {
      this.fetchGetAddRemoteWorkingOverview()
    }
  }
}
</script>
