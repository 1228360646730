<template>
  <div class="row--header" v-if="isDesktop">
    <div class="row__group row__group--main">
      <div class="row__label">
        <a href="javascript:void(0)">
          {{ $t('message.remoteWorking.overview.location') }}
        </a>
      </div>
    </div>

    <div class="row__group row__group--secondary">
      <div class="row__item">
        <div class="row__label">
          <a href="javascript:void(0)">
            {{ $t('message.remoteWorking.overview.from') }}
          </a>
        </div>
      </div>
      <div class="row__item">
        <div class="row__label">
          <a href="javascript:void(0)">
            {{ $t('message.remoteWorking.overview.till') }}
          </a>
        </div>
      </div>
      <div class="row__item">
        <div class="row__label">
          <a href="javascript:void(0)">
            {{ $t('message.remoteWorking.overview.status') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ElementRow from '@elements/ElementRow'

export default {
  name: 'RemoteWorkingTableHeader',
  extends: ElementRow,
  components: {},
  props: {
    content: {
      type: Object,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    isDesktop () {
      return this.device === 'desktop'
    }
  }
}
</script>
